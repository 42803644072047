import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { media } from '../../styles/media';

import { FormInline } from '../Form';
import routes from '../../../conf/routes';

import Icon from '../Icon';
import color from '../../theme/color';

const Root = styled.div`
  ${media.desktop`
    min-width: 195px;
  `};
`;

const Search = ({ large, t, i18n, smallText, light }) => {
  const handleSubmit = value => {
    const pathname = routes.results.langs[i18n.language].split(':')[0];
    const encodedValue = value.length > 0 ? value.split(' ').join('+') : null;

    if (encodedValue) {
      window.location.href = encodeURI(
        `/${i18n.language}/${pathname}${encodedValue}/`
      );
    }
  };

  return (
    <Root large={large}>
      {!large ? (
        <Icon name="search" color={color.brand.white} />
      ) : (
        <FormInline
          label={t(`common:search.placeholder`)}
          icon="search"
          name="search"
          inputType="text"
          smallText={smallText}
          onSubmit={handleSubmit}
          light={light}
          iconColor={light ? color.brand.white : color.brand.accent}
        />
      )}
    </Root>
  );
};

Search.defaultProps = {
  large: false,
  light: false
};

Search.propTypes = {
  large: PropTypes.bool.isRequired,
  t: PropTypes.func,
  i18n: PropTypes.object,
  smallText: PropTypes.bool,
  light: PropTypes.bool
};

export default translate()(Search);
