import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  .Dropdown-root {
    position: relative;
  }

  .Dropdown-control {
    position: relative;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    transition: all 200ms ease;
    line-height: 1.1;
    min-width: 65px;

    text-align: center;
    cursor: pointer;
  }

  .Dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 10px;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -3px;
      width: 1px;
      height: 8px;
      background-color: ${props =>
        props.dark
          ? props.theme.color.brand.primary
          : props.theme.color.brand.white};
      transform: rotate(-45deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: -4px;
      right: -3px;
      width: 1px;
      height: 8px;
      background-color: ${props =>
        props.dark
          ? props.theme.color.brand.primary
          : props.theme.color.brand.white};
      transform: rotate(45deg);
    }
  }

  .Dropdown-menu {
    position: absolute;
    top: -19px;
    left: 5px;

    width: 100%;
    max-height: 200px;

    padding: 5px;

    background-color: white;
    box-sizing: border-box;

    overflow-y: auto;

    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }

  .Dropdown-option {
    position: relative;
    color: #5e5e5e;
    opacity: 0.5;

    padding: 10px 13px;

    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }

  .Dropdown-option:hover,
  .Dropdown-option.is-selected {
    opacity: 1;
  }

  .Dropdown-option.is-selected {
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      top: 40%;
      right: 12px;
      width: 1px;
      height: 8px;
      background-color: ${props => props.theme.color.brand.accent};
      transform: rotate(-135deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 40%;
      right: 7px;
      width: 1px;
      height: 8px;
      background-color: ${props => props.theme.color.brand.accent};
      transform: rotate(135deg);
    }
  }

  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
`;

class Select extends PureComponent {
  handleChange = option => {
    this.props.onSelectChange(option);
  };

  render() {
    const { options, value, placeholder, dark } = this.props;

    return (
      <React.Fragment>
        <DropdownContainer dark={dark}>
          <Dropdown
            options={options}
            onChange={this.handleChange}
            value={value}
            placeholder={placeholder}
          />
        </DropdownContainer>
      </React.Fragment>
    );
  }
}

Select.defaultProps = {
  value: '',
  placeholder: ''
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onSelectChange: PropTypes.func.isRequired,
  dark: PropTypes.bool
};

export default Select;
