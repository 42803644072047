import React from 'react';
import styled from 'styled-components';
import { translate } from 'react-i18next';
import { Link } from '../../../routes';
import PropTypes from 'prop-types';

import { media } from '../../styles/media';

const Root = styled.a``;

const Img = styled.img`
  display: block;
  width: 110px;
  height: auto;

  ${media.desktop`
    width: ${({ showBackground }) => (showBackground ? '114px' : '150px')};
  `};

  transition: width 0.5s ease-in-out;
`;

const Logo = ({ isOpen, showBackground, i18n }) => {
  const imageName = isOpen ? 'logo-dark.svg' : 'logo.svg';

  return (
    <Link route={`/${i18n.language}/`} passHref>
      <Root>
        <Img
          src={`/static/images/${imageName}`}
          alt="Logo Grand Hôtel-Dieu"
          showBackground={showBackground}
        />
      </Root>
    </Link>
  );
};

Logo.defaultProps = {
  isOpen: false
};

Logo.propTypes = {
  i18n: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  showBackground: PropTypes.bool
};

export default translate()(Logo);
