import React from 'react';
import styled, { keyframes } from 'styled-components';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Category from './Category';

import { Container } from '../../Grid';

const widthIn = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

const widthOut = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
`;

const Root = styled.nav`
  margin-top: ${props => (props.isMobile ? '50px' : '120px')};
  margin-bottom: ${props => !props.isMobile && '40px'};
`;

const CategoryContainer = styled.ul`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  justify-content: ${props => !props.isMobile && 'center'};
  position: relative;
  width: 100%;

  &.fadeOut {
    &:before {
      animation: ${widthOut} 0.2s ease-in-out forwards;
    }
  }

  &.fadeIn {
    &:before {
      animation: ${widthIn}
        ${({ categoriesLength }) => 0.3 + categoriesLength * 0.05 + 's'}
        ease-in-out forwards;
      animation-delay: 0.4s;
    }
  }

  ${({ isMobile }) =>
    !isMobile &&
    `
      &:before {
        content: '';
        display: block;
        width: 0%;
        height: 1px;
        background-color: rgba(128, 139, 145, 0.2);
        position: absolute;
        top: 0;
        left: 0;
      }
  `};
`;

const Nav = ({ onCloseMenu, categories, isMobile, navbarFadeClass }) => {
  return (
    <Root isMobile={isMobile}>
      <Container isMobile={isMobile}>
        <CategoryContainer
          isMobile={isMobile}
          className={navbarFadeClass}
          categoriesLength={categories.length - 1}
        >
          {categories.map((category, index) => {
            return (
              <Category
                key={category.id}
                onCloseMenu={onCloseMenu}
                category={category}
                isMobile={isMobile}
                navbarFadeClass={navbarFadeClass}
                index={index}
              />
            );
          })}
        </CategoryContainer>
      </Container>
    </Root>
  );
};

Nav.propTypes = {
  onCloseMenu: PropTypes.func,
  categories: PropTypes.array,
  isMobile: PropTypes.bool,
  navbarFadeClass: PropTypes.string
};

export default translate()(Nav);
