import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import styled from 'styled-components';
import { Link } from '../Button';
import { media } from '../../styles/media';

const Root = styled(Link)`
  position: relative;
  color: ${props => props.theme.color.white};

  ${media.desktop`
    display: none;
  `};
`;

const Button = ({ isOpen, t }) => {
  const handleClick = () => {
    document.querySelector('body').classList.toggle('no-scroll');
  };

  return (
    <Root onClick={handleClick} as="button">
      {isOpen ? t('common:nav.menu.close') : t('common:nav.menu.open')}
    </Root>
  );
};

Button.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func
};

export default translate()(Button);
