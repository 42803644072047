import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import Link from '../Link/Link';
import getGhdSocialNetworks from '../../apollo/graphql/navigation/getGhdSocialNetworks';
import createContext from '../../utils/format/createContext';
import { Query } from 'react-apollo';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0px, -50%, 0px);
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
  to {
    opacity: 0;
    transform: translate3d(0px, -50%, 0px);
  }
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => !props.isMobile && '25px'};

  margin-top: ${props => props.isMobile && '15px'};

  a {
    margin-left: ${props => (props.isMobile ? '18px' : '8px')};
    width: ${props => (props.isMobile ? '25px' : '20px')};
    height: ${props => (props.isMobile ? '25px' : '20px')};

    &:first-of-type {
      margin-left: 0px;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    opacity: 0;
    transform: translate3d(0px, -50%, 0px);
  `};

  &.fadeOut {
    animation: ${fadeOut} 0.2s ease-in-out forwards;
  }

  &.fadeIn {
    animation: ${fadeIn} 0.3s ease-in-out forwards;
    animation-delay: 0.4s;
  }
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;

  transition: opacity 0.1s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

const SocialNetworks = ({ light, i18n, isMobile, navbarFadeClass }) => {
  return (
    <Root isMobile={isMobile} className={isMobile ? navbarFadeClass : ''}>
      <Query
        query={getGhdSocialNetworks}
        context={createContext(i18n.language)}
      >
        {({ loading, error, data }) => {
          if (!loading && !error && data && data.getGhdSocialNetworks) {
            const { getGhdSocialNetworks } = data;
            return (
              <>
                {getGhdSocialNetworks.map(socialNetwork => {
                  const {
                    id,
                    name,
                    url,
                    icon_light,
                    icon_dark
                  } = socialNetwork;
                  return (
                    <Link key={id} href={url}>
                      <Icon src={light ? icon_light : icon_dark} alt={name} />
                    </Link>
                  );
                })}
              </>
            );
          }
          return null;
        }}
      </Query>
    </Root>
  );
};

SocialNetworks.defaultProps = {
  light: false
};

SocialNetworks.propTypes = {
  light: PropTypes.bool,
  i18n: PropTypes.object,
  isMobile: PropTypes.bool,
  navbarFadeClass: PropTypes.string
};

export default translate()(SocialNetworks);
