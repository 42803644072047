import styled from 'styled-components';
import { media } from '../../styles/media';

export const Root = styled.div`
  position: absolute;
  top: ${props => props.theme.layout.gutter * -4.5}px;
  left: 50%;
  width: 220px;
  max-height: 80px;
  background-color: ${props => props.theme.color.white};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transform: translate3d(-50%, 0, 0);
  transition: width 200ms 100ms ease-in-out, max-height 200ms ease-in-out;
  overflow: hidden;
  z-index: 2;

  ${props =>
    props.open &&
    `
    width: calc(100% - ${props.theme.layout.gutter * 1.25}px);
    max-height: 1000px;
    transition: width 200ms ease-in-out, max-height 200ms 100ms ease-in-out;
  `};

  ${media.tablet`
    top: ${props => props.theme.layout.gutter * -7.5}px;

    width: 290px;
    max-height: 125px;

    ${props =>
      props.open &&
      `
        width: 80vw;
        max-height: 1000px;
    `};
  `};

  ${media.desktop`
      ${props =>
        props.open &&
        `
        width: ${props.theme.layout.width}px;
        max-height: 1000px;
    `};
  `};
`;

export const Content = styled.ul`
  padding: ${props => props.theme.layout.gutter / 4}px
    ${props => props.theme.layout.gutter * 1.5}px
    ${props => props.theme.layout.gutter / 4}px
    ${props => props.theme.layout.gutter * 1.5}px;

  ${media.tablet`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: ${props => props.theme.layout.gutter}px ${props =>
    props.theme.layout.gutter * 3.5}px ${props =>
    props.theme.layout.gutter * 1.75}px ${props =>
    props.theme.layout.gutter * 3.5}px;
  `};
`;

export const Item = styled.li``;

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: ${props => props.theme.font.brand.secondary};
  font-size: 13px;
  line-height: 18.2px;
  text-align: center;
  color: ${props => props.theme.color.brand.primary};

  height: 80px;
  cursor: pointer;

  ${media.tablet`
    height: 125px;
  `};
`;

export const VerticalLine = styled.div`
  ${media.tablet`
    width: 1px;
    height: 33px;
    background-color: ${({ theme }) => theme.color.brand.lightGrey};
    margin-top: 10px;
  `};
`;
