import React from 'react';
import PropTypes from 'prop-types';
import { media } from '../../styles/media';
import { translate } from 'react-i18next';
import Burger from './Burger';
import styled from 'styled-components';

import { Container } from '../Grid';
import LanguageSwitcher from './LanguageSwitcher';
import Search from './Search';
import SocialNetworks from './SocialNetworks';

import { DesktopOnly, MobileOnly } from '../Layout';

const Root = styled.div`
  position: relative;
  pointer-events: all;

  z-index: 2;

  ${Container} {
    padding-top: ${props => props.theme.layout.gutter * 1.25}px;
  }

  ${media.desktop`

    ${Container} {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      padding-top: 47.75px;
    }
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MenuButton = styled.p`
  font-size: 16px;
  font-family: ${props => props.theme.font.brand.secondary};
  color: ${props =>
    props.isOpen || props.backgroundEnabled
      ? props.theme.color.brand.grey
      : props.theme.color.brand.white};
  padding-left: 10px;
  cursor: pointer;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SearchContainer = styled.div`
  margin-top: 100px;
`;

const Header = ({ isOpen, onMenuToggle, backgroundEnabled, t, isMobile }) => {
  const onHover = () => {
    onMenuToggle(true);
  };

  const handleClick = () => {
    onMenuToggle(!isOpen);
  };

  return (
    <Root isOpen={isOpen} backgroundEnabled={backgroundEnabled}>
      <Container className="column">
        <Wrapper isOpen={isOpen}>
          <MenuWrapper
            onMouseEnter={!isMobile && onHover}
            onClick={handleClick}
          >
            <Burger isOpen={isOpen} backgroundEnabled={backgroundEnabled} />

            <DesktopOnly>
              <MenuButton isOpen={isOpen} backgroundEnabled={backgroundEnabled}>
                {isOpen
                  ? t('common:nav.menu.close')
                  : t('common:nav.menu.open')}
              </MenuButton>
            </DesktopOnly>
          </MenuWrapper>

          <MobileOnly>
            <LanguageSwitcher
              dark={isOpen || backgroundEnabled}
              noMargin
              isMobile
            />
          </MobileOnly>

          <DesktopOnly>
            <RightWrapper>
              <Search
                large
                light={isOpen ? false : backgroundEnabled ? false : true}
              />

              <LanguageSwitcher dark={isOpen || backgroundEnabled} />
              <SocialNetworks
                light={isOpen ? false : backgroundEnabled ? false : true}
              />
            </RightWrapper>
          </DesktopOnly>
        </Wrapper>

        <MobileOnly>
          {isOpen && (
            <SearchContainer>
              <Search large />
            </SearchContainer>
          )}
        </MobileOnly>
      </Container>
    </Root>
  );
};

Header.defaultProps = {
  isOpen: false
};

Header.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  backgroundEnabled: PropTypes.bool,
  isMobile: PropTypes.bool
};

export default translate()(Header);
