import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectSearchBox } from 'react-instantsearch-dom';

import Icon from '../Icon';
import Input from '../Form/Input';
import color from '../../theme/color';
import { Root, InputWrapper, IconWrapper } from './styles';
import { utf8Decode } from '../../utils/format/utf8Decode';

class SearchBox extends PureComponent {
  constructor(props) {
    super(props);
    const { currentRefinement } = props;
    this.state = {
      currentRefinement
    };
  }

  componentDidMount() {
    const { searchState } = this.props;
    if (searchState) {
      this.handleChange(utf8Decode(unescape(searchState)));
    }
  }

  handleChange = currentRefinement => {
    this.setState({
      currentRefinement
    });

    const currentRefinementLength = currentRefinement.length;

    if (currentRefinementLength <= 0 || currentRefinementLength >= 3) {
      this.props.refine(currentRefinement);
    }
  };

  render() {
    const { label } = this.props;
    const { currentRefinement } = this.state;
    return (
      <Root>
        <InputWrapper>
          <Input
            type="text"
            label={label}
            value={currentRefinement}
            focus={!currentRefinement.trim() !== ''}
            onChange={event => this.handleChange(event.target.value)}
          />
          <IconWrapper>
            <Icon name="search" color={color.primary} />
          </IconWrapper>
        </InputWrapper>
      </Root>
    );
  }
}

SearchBox.propTypes = {
  label: PropTypes.string,
  currentRefinement: PropTypes.string,
  refine: PropTypes.func,
  searchState: PropTypes.string
};

export default connectSearchBox(SearchBox);
