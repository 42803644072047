import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import Header from './Header';
import Nav from './Nav';
import { media } from '../../styles/media';
import Logo from './Logo';
import { Query } from 'react-apollo';
import getMenu from '../../apollo/graphql/navigation/getMenu';
import createContext from '../../utils/format/createContext';
import useMobile from '../../utils/useMobile';
import SocialNetworks from './SocialNetworks';

import { MobileOnly } from '../Layout';

const ANIMATION_DURATION = 500; // ms

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.4s cubic-bezier(0.3, 0.1, 0.3, 1);
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  width: 100%;
  height: ${({ isOpen }) => isOpen && '100%'};

  ${media.tablet`
    height: auto;
  `}

  z-index: 5;

  transform: ${props =>
    !props.firstRendering && !props.isOpen && props.visible
      ? 'translateY(0)'
      : !props.firstRendering &&
        !props.isOpen &&
        !props.visible &&
        'translateY(-100%)'};

  .hide {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    min-height: ${({ isOpen }) => (isOpen ? '100vh' : '110px')};
    max-height: ${({ isOpen }) => (isOpen ? '100vh' : '110px')};
    ${media.desktop`
      min-height: ${props => (props.isOpen ? '100%' : '133px')};
      max-height: ${props => (props.isOpen ? '100%' : '133px')};
    `}
    background-color: ${props => props.theme.color.brand.white};

    transform: ${props =>
      props.showBackground || props.isOpen
        ? 'translate3d(0px, 0px, 0px)'
        : 'translate3d(0px, -100%, 0px)'};
    transition: transform ${ANIMATION_DURATION}ms ease-in-out,
      max-height ${ANIMATION_DURATION}ms ease-in-out, min-height ${ANIMATION_DURATION}ms ease-in-out;
    will-change: transform;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  pointer-events: auto;
  top: ${props => props.theme.layout.gutter * 1.25}px;
  left: 50%;

  transform: translate3d(-50%, 0, 0);
  z-index: 6;
`;

const MenuContentWrapper = styled.div`
  overflow-y: ${({ isOpen }) => isOpen && 'scroll'};
  height: ${({ isOpen }) => isOpen && '100%'};

  ${media.tablet`
    height: auto;
    overflow-y: hidden;
  `};
`;

const Menu = ({ i18n }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [firstRendering, setFirstRendering] = useState(true);
  const [showBackground, setShowBackground] = useState(false);
  const [navbarFadeClass, setNavbarFadeClass] = useState('hide');

  const { isMobile } = useMobile();

  const handleMenuToggle = isMenuOpen => {
    setIsOpen(isMenuOpen);
  };

  useEffect(
    () => {
      if (!firstRendering) {
        setNavbarFadeClass(!isOpen ? 'fadeOut' : 'fadeIn');
      }

      setFirstRendering(false);

      if (isOpen) {
        document.querySelector('body').classList.add('no-scroll');
      } else {
        document.querySelector('body').classList.remove('no-scroll');
      }
    },
    [isOpen]
  );

  const handleCloseMenu = () => {
    document.querySelector('body').classList.remove('no-scroll');

    setIsOpen(false);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const showMenu =
      firstRendering ||
      currentScrollPos < (isMobile ? 130 : 150) ||
      prevScrollPos > currentScrollPos;
    setFirstRendering(false);

    setVisible(showMenu);
    setShowBackground(currentScrollPos > (isMobile ? 600 : 800));
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(
    () => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    },
    [handleScroll, prevScrollPos, visible, showBackground, firstRendering]
  );

  return (
    <Root
      className="menu"
      isOpen={isOpen}
      visible={visible}
      showBackground={showBackground}
      firstRendering={firstRendering}
      onMouseLeave={() => handleMenuToggle(false)}
    >
      <MenuContentWrapper isOpen={isOpen}>
        <Header
          isOpen={isOpen}
          backgroundEnabled={showBackground}
          onMenuToggle={handleMenuToggle}
          isMobile={isMobile}
        />

        <LogoWrapper>
          <Logo
            isOpen={isOpen || showBackground}
            showBackground={showBackground}
          />
        </LogoWrapper>

        <Query
          query={getMenu}
          context={createContext(i18n.language)}
          variables={{ type: 'HEADER' }}
        >
          {({ loading, error, data }) => {
            if (!loading && !error && data && data.getMenu) {
              const { getMenu } = data;
              return (
                <>
                  <Nav
                    categories={getMenu.categoriesDetail}
                    onCloseMenu={handleCloseMenu}
                    isMobile={isMobile}
                    navbarFadeClass={navbarFadeClass}
                  />
                  <MobileOnly>
                    <SocialNetworks
                      isMobile
                      navbarFadeClass={navbarFadeClass}
                    />
                  </MobileOnly>
                </>
              );
            }
            return null;
          }}
        </Query>
      </MenuContentWrapper>
    </Root>
  );
};

Menu.propTypes = {
  i18n: PropTypes.object
};

export default translate()(Menu);
