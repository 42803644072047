import React from 'react';
import Proptypes from 'prop-types';

import { translate } from 'react-i18next';

import { Container } from '../../Grid';
import { media } from '../../../styles/media';
import Menu from '../../Menu';

import styled from 'styled-components';
import BackgroundVideo from './BackgroundVideo';
import theme from '../../../theme';
import useMobile from '../../../utils/useMobile';

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.brand.primary};
  color: ${({ theme }) => theme.color.brand.white};
  overflow: hidden;
`;

const AbsoluteContent = styled.div`
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 0;
  right: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 665px;
`;

const H1 = styled.h1`
  text-align: center;
`;

const MainTitle = styled.span`
  display: block;
  font-family: ${props => props.theme.font.brand.secondary};
  font-size: ${({ theme }) => theme.font.homepage.mainTitle.mobileFontSize};
  line-height: ${({ theme }) => theme.font.homepage.mainTitle.mobileLineHeight};
  font-weight: ${({ theme }) => theme.font.fontWeight};
  text-align: ${({ align }) => align || 'left'};
  color: ${({ theme, color }) => color || theme.color.brand.primary};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

  ${media.tablet`
  font-size: ${({ theme }) => theme.font.homepage.mainTitle.fontSize};
  line-height: ${({ theme }) => theme.font.homepage.mainTitle.lineHeight};
  `};
`;

const SubTitle = styled.span`
  display: block;
  font-family: ${theme.font.brand.secondary};
  font-size: ${({ theme }) => theme.font.homepage.subTitle.mobileFontSize};
  line-height: ${({ theme }) => theme.font.homepage.subTitle.mobileLineHeight};
  font-weight: ${({ theme }) => theme.font.fontWeight};
  text-align: ${({ align }) => align || 'left'};
  color: ${({ theme, color }) => color || theme.color.brand.primary};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

  ${media.tablet`
    font-size: ${({ theme }) => theme.font.homepage.subTitle.fontSize};
    line-height: ${({ theme }) => theme.font.homepage.subTitle.lineHeight};
  `};
`;

const P = styled.p`
  text-align: ${({ align }) => align || 'left'};
  color: ${({ theme, color }) => color || theme.color.brand.grey};
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 57px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.color.brand.white};

  ${media.tablet`
    margin-top: 40px;
  `};
`;

const Hero = ({ data }) => {
  const { isMobile } = useMobile();

  const {
    main_title,
    sub_title,
    description,
    section_presentation_video,
    section_presentation_bg
  } = data;

  return (
    <Wrapper>
      <Menu />
      <BackgroundVideo
        videoUrl={section_presentation_video}
        backgroundUrl={section_presentation_bg}
        isMobile={isMobile}
      />
      <AbsoluteContent>
        <Container>
          <ContentWrapper>
            <H1>
              <MainTitle
                color={theme.color.brand.white}
                align="center"
                marginBottom={5}
              >
                {main_title}
              </MainTitle>
              <SubTitle
                color={theme.color.brand.white}
                align="center"
                marginBottom={25}
              >
                {sub_title}
              </SubTitle>
            </H1>
            <P color={theme.color.brand.white} align="center">
              {description}
            </P>
            <VerticalLine />
          </ContentWrapper>
        </Container>
      </AbsoluteContent>
    </Wrapper>
  );
};

Hero.propTypes = {
  data: Proptypes.object.isRequired
};

export default translate()(Hero);
