import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '../../Grid';
import { DesktopOnly } from '../../Layout';
import Button from '../../Button';
import theme from '../../../theme';
import { translate } from 'react-i18next';
import { media } from '../../../styles/media';

const Overlay = styled.div`
  position: absolute;
  z-index: 2;
  background-color: black;
  opacity: 0.2;
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  z-index: 1;
  transform: translateY(-50%) translateX(-50%);
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 42px;
  left: 36px;
`;

const StyledButton = styled(Button)`
  position: relative;
  z-index: 10;
  left: -30px;

  ${media.tablet`
    left: unset;
  `};
`;

const BackgroundVideo = ({ videoUrl, backgroundUrl, isMobile, t }) => {
  const videoRef = useRef(null);
  const [videoIsPaused, setIsVideoPaused] = useState(false);

  const togglePlayVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsVideoPaused(false);
    } else {
      videoRef.current.pause();
      setIsVideoPaused(true);
    }
  };

  return (
    <>
      <Overlay />
      <Video
        ref={videoRef}
        poster={backgroundUrl}
        autoPlay={true}
        playsInline
        loop
        muted
      >
        <source src={videoUrl} type="video/mp4" />
      </Video>
      {videoUrl && (
        <ButtonWrapper>
          <StyledButton
            iconType="play"
            textColor={theme.color.brand.white}
            onClick={togglePlayVideo}
          >
            {videoIsPaused
              ? t('common:button.play_video')
              : t('common:button.pause_video')}
          </StyledButton>
        </ButtonWrapper>
      )}
    </>
  );
};

BackgroundVideo.propTypes = {
  videoUrl: PropTypes.string,
  backgroundUrl: PropTypes.string,
  isMobile: PropTypes.bool
};

export default translate()(BackgroundVideo);
