import styled from 'styled-components';

import { media } from '../../styles/media';

import { Container } from '../Grid';
import { Root as Cartridge } from '../Cartridge/style';

export const Root = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  height: auto;
  min-height: ${props => (props.adaptive ? 'auto' : '260px')};

  background: ${props => props.theme.color.lightGrey};
  background-position: center;
  background-size: cover;

  background-image: linear-gradient(
      ${props =>
        props.noOverlay
          ? 'rgba(45, 49, 85, 0.5), rgba(45, 49, 85, 0.5)'
          : '0deg, rgba(5, 66, 94, 0.94), rgba(5, 66, 94, 0.94)'}
    ),
    url(${props => props.backgroundMobile});

  ${media.tablet`
    min-height: ${props => (props.adaptive ? 'auto' : '420px')};
    background-image: linear-gradient(
      ${props =>
        props.noOverlay
          ? 'rgba(45, 49, 85, 0.5), rgba(45, 49, 85, 0.5)'
          : '0deg, rgba(5, 66, 94, 0.94), rgba(5, 66, 94, 0.94)'}
    ),
    url(${props => props.background});
  `};
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.font.black};
  font-size: 1.8rem;
  letter-spacing: 5px;
  line-height: 2.4rem;
  text-align: center;
  text-transform: uppercase;

  color: ${props => props.theme.color.white};

  margin: ${props => props.theme.layout.gutter / 2}px 0;

  ${media.tablet`
    margin: ${props => props.theme.layout.gutter * 2}px 0;
    font-size: ${props => (props.functional ? 1.8 : 2.8)}rem;
    line-height: ${props => (props.functional ? 1.8 : 2.8)}rem;
  `};
`;

export const Wrapper = styled.div`
  width: 100%;

  > ${Container} {
    position: static;

    padding-top: ${props => props.theme.layout.gutter * 8.75}px;
    padding-bottom: ${props => props.theme.layout.gutter * 3}px;
  }

  ${media.tablet`
    > ${Container} {
      margin-bottom: 0;
      padding-top: ${props => props.theme.layout.gutter * 9.5}px;
    }
  `};

  ${Cartridge} {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 50%, 0);
  }
`;

export const Img = styled.img``;

export const Search = styled.div`
  .icon-search:before {
    color: ${props => props.theme.color.primary};
  }

  ${media.tablet`
    max-width: 70vw;

    margin: 0 auto;
  `};

  ${media.desktop`
    max-width: 40vw;
  `};
`;
