import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import i18n from '../../utils/I18N/index';
import { getLangs } from '../../services/lang/index';
import Select from '../Select';

const Root = styled.div`
  font-family: ${props => props.theme.font.brand.secondary};
  font-size: 1.25rem;
  letter-spacing: 0.13rem;
  text-transform: uppercase;
  color: ${props =>
    props.dark
      ? props.theme.color.brand.primary
      : props.theme.color.brand.white};
  margin-right: ${props => !props.noMargin && '20px'};

  ${props =>
    !props.isMobile &&
    `
    height: 25px;
    display: flex;
    align-items: center;
  `};
`;

const LanguageSwitcher = ({ dark, noMargin, isMobile }) => {
  const handleChange = option => {
    const lang = option.value;
    i18n.changeLanguage(lang, () => {
      window.location.href = `/${lang}/`;
    });
  };

  const formatLang = lang => {
    return lang && lang.substring(0, 2);
  };

  const value = formatLang(i18n.language);
  const options = getLangs();

  return (
    <Root dark={dark} noMargin={noMargin} isMobile={isMobile}>
      <Select
        options={options}
        onSelectChange={handleChange}
        value={value}
        placeholder="Lang"
        dark={dark}
      />
    </Root>
  );
};

LanguageSwitcher.propTypes = {
  dark: PropTypes.bool,
  noMargin: PropTypes.bool,
  isMobile: PropTypes.bool
};

export default LanguageSwitcher;
