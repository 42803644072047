import React, { PureComponent } from 'react';
import Head from 'next/head';

import { translate } from 'react-i18next';

// Components
import { Page, Section } from '../Layout';
import { Button, WrapperButton } from '../Button';
import { Container } from '../Grid';
import Header from '../Header';

// Styles
import { Wrapper, Cart } from './styles';
import styled from 'styled-components';
import Text from '../Typography/Text';
import theme from '../../theme';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

class Error extends PureComponent {
  render() {
    const { i18n, t, statusCode, message } = this.props;
    return (
      <Page>
        <Head>
          <title>Ouppsss</title>
        </Head>
        <Header image="/static/images/notfound/header.jpg" />
        <Section>
          <Container>
            <Wrapper>
              <Cart>
                <img
                  src={`/static/images/notfound/${
                    statusCode ? statusCode : 500
                  }.png`}
                />
              </Cart>
              <Text
                variant="heading2"
                color={theme.color.brand.primary}
                as="h1"
                align="center"
              >
                {statusCode == 404 && t('common:notfound.title')}
                {statusCode == 500 && t('common:servererror.title')}
              </Text>
              <Text
                variant="heading3"
                color={theme.color.brand.grey}
                as="p"
                align="center"
                marginTop="10px"
              >
                {statusCode == 404 && t('common:notfound.content')}
                {statusCode != 404 && message}
              </Text>
              {statusCode == 404 && (
                <ButtonWrapper>
                  <Button href="/">{t('common:notfound.button')}</Button>
                </ButtonWrapper>
              )}
            </Wrapper>
          </Container>
        </Section>
      </Page>
    );
  }
}

export default translate()(Error);
