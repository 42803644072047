import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import SearchBox from './SearchBox';

class Search extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <SearchBox
        label={t(`common:search.placeholder`)}
        searchState={this.props.searchState}
      />
    );
  }
}

Search.propTypes = {
  t: PropTypes.func,
  searchState: PropTypes.string
};

export default translate()(Search);
