import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  width: 23px;
  height: 8px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
`;

const Line = styled.span`
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: ${props =>
    props.isOpen || props.backgroundEnabled
      ? props.theme.color.brand.primary
      : props.theme.color.brand.white};
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;

  &:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
  }

  &:nth-of-type(2) {
    top: 4px;
    transform-origin: left center;
  }

  &:nth-of-type(3) {
    top: 8px;
    transform-origin: left center;
  }

  ${props =>
    props.isOpen &&
    `
    &:nth-of-type(1) {
      transform: rotate(45deg);
      top: -4px;
      left: 3px;
    }

    &:nth-of-type(2) {
      width: 0%;
      opacity: 0;
    }

    &:nth-of-type(3) {
      transform: rotate(-45deg);
      top: 12px;
      left: 3px;
    }
`};
`;

const Burger = ({ isOpen, backgroundEnabled }) => {
  return (
    <Root isOpen={isOpen} backgroundEnabled={backgroundEnabled}>
      <Line isOpen={isOpen} backgroundEnabled={backgroundEnabled} />
      <Line isOpen={isOpen} backgroundEnabled={backgroundEnabled} />
      <Line isOpen={isOpen} backgroundEnabled={backgroundEnabled} />
    </Root>
  );
};

Burger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  backgroundEnabled: PropTypes.bool.isRequired
};

export default translate()(Burger);
