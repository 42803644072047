import styled from 'styled-components';

import { media } from '../../styles/media';
import {
  Root as FilterRoot,
  Content as FilterContent,
  Header as FilterHeader,
  HeaderContentWrapper as FilterHeaderWrapper,
  VerticalLine as FilterVerticalLine
} from '../Filter/styles';

export const Root = styled.div`
  .icon-search:before {
    color: ${props => props.theme.color.primary};
  }

  ${media.tablet`
    max-width: 70vw;
    margin: 0 auto;
  `};

  ${media.desktop`
    max-width: 40vw;
  `};
`;

export const InputWrapper = styled.div`
  position: relative;
  color: ${props => props.theme.color.white};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
  transition: right 0.2s ease-in-out;
  z-index: 1;

  > .icon {
    font-size: 1.5rem;
  }
`;

export const FilterCategory = styled.li`
  font-family: ${props => props.theme.font.black};
  font-size: 1.3rem;
  letter-spacing: 2.5px;
  text-transform: uppercase;

  color: ${props => props.theme.color.secondary};

  ${media.tablet`
    width: 50%;
    flex-shrink: 0;

    font-size: 1.6rem;
    letter-spacing: 2.67px;
  `};

  ${media.desktop`
    width: auto;
    flex: 1 1 auto;
  `};
`;

export const FilterCategoryContent = styled.ul`
  margin: ${props => props.theme.layout.gutter / 4}px 0
    ${props => props.theme.layout.gutter}px
    ${props => props.theme.layout.gutter}px;

  ${media.desktop`
    margin: ${props => props.theme.layout.gutter / 2}px 0 0 ${props =>
    props.theme.layout.gutter}px;
  `};
`;

export const FilterItem = styled.li`
  padding: ${props => props.theme.layout.gutter / 4}px 0;

  font-family: ${props => props.theme.font.bold};
  font-size: 1.5rem;
  text-transform: initial;
  letter-spacing: 0;

  color: ${props => props.theme.color.darkGrey};

  ${media.tablet`
    font-size: 1.6rem;
    padding: ${props => props.theme.layout.gutter * 0.4}px 0;
  `};
`;

export const Filter = styled(FilterRoot)`
  top: -80px;
  ${media.tablet`
      top: -70px;
  `};
`;

export const NoResultsContainer = styled.div`
  font-family: ${props => props.theme.font.bold};
  margin: 100px 0;
  text-align: center;

  ${media.tablet`
    margin: 200px 0;
  `};
`;

export const NoResultsTitle = styled.h1`
  color: ${props => props.theme.color.black};
  font-size: 3.5rem;

  ${media.tablet`
    font-size: 5.5rem;
  `};
`;

export const NoResultsContent = styled.p`
  color: ${props => props.theme.color.brown};
  font-size: 2rem;

  ${media.tablet`
    font-size: 3.2rem;
  `};
`;

export { FilterContent, FilterHeader, FilterHeaderWrapper, FilterVerticalLine };
