import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Menu from '../Menu';
import { Container } from '../Grid';
import Cartridge from '../Cartridge';
import Search from '../Search';

import { Root, Wrapper } from './styles';
import Text from '../Typography/Text';
import theme from '../../theme';
import styled from 'styled-components';

const SearchWrapper = styled.div`
  margin-top: 30px;
`;

class Header extends PureComponent {
  renderSearch = () => {
    return (
      this.props.functional && (
        <SearchWrapper>
          <Search searchState={this.props.searchState} />
        </SearchWrapper>
      )
    );
  };

  renderCartridge = () => {
    const { cartridge } = this.props;
    return <Cartridge title={cartridge} />;
  };

  render() {
    const {
      title,
      image,
      imageMobile,
      functional,
      noOverlay,
      search,
      adaptive,
      subtitle,
      cartridge
    } = this.props;

    return (
      <Root
        background={image}
        backgroundMobile={imageMobile}
        functional={functional}
        adaptive={adaptive}
        noOverlay={noOverlay}
      >
        <Menu />
        <Wrapper>
          <Container>
            {title && (
              <Text
                variant="heading2"
                as="h1"
                align="center"
                color={theme.color.brand.white}
              >
                {title}
              </Text>
            )}
            {subtitle && (
              <Text
                variant="heading3"
                align="center"
                color={theme.color.brand.white}
                as="p"
                marginTop="20px"
              >
                {subtitle}
              </Text>
            )}
            {search && this.renderSearch()}
            {cartridge && this.renderCartridge()}
          </Container>
        </Wrapper>
      </Root>
    );
  }
}

Header.defaultProps = {
  functional: false,
  adaptive: false,
  search: true
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageMobile: PropTypes.string,
  functional: PropTypes.bool,
  search: PropTypes.bool,
  adaptive: PropTypes.bool,
  cartridge: PropTypes.string,
  searchState: PropTypes.string,
  noOverlay: PropTypes.bool
};

export default Header;
