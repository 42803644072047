import styled from 'styled-components';
import { media } from '../../styles/media';

export const Wrapper = styled.div`
  margin-top: -135px;

  h1 {
    margin-top: 30px;
    margin-bottom: 6px;
  }

  p {
    margin-bottom: 24px;
  }

  ${media.tablet`
    margin-top: -230px;

    h1 {
      margin-top: 50px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 40px;
    }
  `};
`;

export const Cart = styled.div`
  align-items: center;
  color: ${props => props.theme.color.darkGrey};
  background-color: ${props => props.theme.color.white};
  display: flex;
  font-family: ${props => props.theme.font.black};
  font-size: 1.4rem;
  justify-content: center;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  max-width: 352px;
  padding: 2rem 4.2rem;

  img {
    width: 100%;
  }

  ${media.tablet`
    width: 793px;
    max-width: 100%;
    padding: 5.8rem 11.25rem;
  `};
`;
