const i18n = require('i18next');
const XHR = require('i18next-xhr-backend');
const LanguageDetector = require('i18next-browser-languagedetector');

// const dev = process.env.NODE_ENV !== 'production';

const options = {
  debug: false,
  fallbackLng: 'fr',
  preload: ['fr', 'en', 'es', 'it'], // preload all langages
  whitelist: ['fr', 'en', 'es', 'it'],
  load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE

  // have a common namespace used around the full app
  ns: ['common'],
  defaultNS: 'common',

  saveMissing: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    }
  },
  detection: {
    order: ['path', 'cookie', 'localStorage', 'navigator'],
    caches: ['cookie', 'localStorage'],
    cookieMinutes: 365 * 24 * 60 // one year
  }
};

// for browser use xhr backend to load translations and browser lng detector
if (process.browser) {
  i18n
    .use(XHR)
    // .use(Cache)
    .use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) i18n.init(options);

// a simple helper to getInitialProps passed on loaded i18n data
i18n.getInitialProps = (req, namespaces) => {
  if (!namespaces && i18n && i18n.options) namespaces = i18n.options.defaultNS;
  if (typeof namespaces === 'string') namespaces = [namespaces];
  const initialI18nStore = {};

  if (req.i18n) {
    req.i18n.toJSON = () => null; // do not serialize i18next instance and send to client

    req.i18n.languages.forEach(l => {
      initialI18nStore[l] = {};
      namespaces.forEach(ns => {
        initialI18nStore[l][ns] =
          (req.i18n.services.resourceStore.data[l] || {})[ns] || {};
      });
    });
  }

  return {
    i18n: req.i18n, // use the instance on req - fixed language on request (avoid issues in race conditions with lngs of different users)
    initialI18nStore,
    initialLanguage: req.i18n && req.i18n.language
  };
};

module.exports = i18n;
