import styled from 'styled-components';

import { media } from '../../styles/media';

export const Root = styled.div`
  align-items: center;
  color: ${props => props.theme.color.darkGrey};
  background-color: ${props => props.theme.color.white};
  display: flex;
  font-family: ${props => props.theme.font.black};
  font-size: 1.4rem;
  justify-content: center;
  letter-spacing: 2.5px;
  min-height: 4rem;
  text-align: center;
  text-transform: uppercase;
  padding: 0 4rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;

  ${media.tablet`
    min-height: 5rem;
    padding: 0 7rem;
  `};
`;
