import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import Icon from '../../Icon';
import color from '../../../theme/color';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0px, -50px, 0px);
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
  to {
    opacity: 0;
    transform: translate3d(0px, -50px, 0px);
  }
`;

const Root = styled.li`
  margin-bottom: ${props => props.isMobile && '25px'};

  margin-top: ${props => !props.isMobile && '40px'};
  margin-left: ${props => !props.isMobile && '15px'};
  margin-right: ${props => !props.isMobile && '15px'};

  max-width: ${props => !props.isMobile && '100px'};

  opacity: 0;
  transform: translate3d(0px, -50px, 0px);

  &.fadeOut {
    animation: ${fadeOut} 0.2s ease-in-out forwards;
  }

  &.fadeIn {
    animation: ${fadeIn} 0.3s ease-in-out forwards;
    animation-delay: ${({ index }) => 0.4 + index * 0.05 + 's'};
  }
`;

const CategoryTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: ${props => props.isMobile && 'pointer'};

  color: ${props =>
    props.isMobile
      ? props.isOpen
        ? props.theme.color.brand.accent
        : props.theme.color.brand.primary
      : props.theme.color.brand.accent};
  transition: color 0.3s ease;

  ${props =>
    props.isMobile &&
    `
      &:hover {
        color: ${props => props.theme.color.brand.accent};
      }
  `};
`;

const CategoryName = styled.h2`
  font-family: ${props => props.theme.font.brand.secondary};
  font-size: 18px;
  text-transform: uppercase;
`;

const IconCustom = styled(Icon)`
  transform: ${props => (props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.3s ease;
`;

const CategoryItems = styled.ul`
  margin-top: 25px;
`;

const CategoryItem = styled.li`
  margin-bottom: 8px;

  a {
    font-size: 14px;
    color: ${props => props.theme.color.brand.grey};
    transition: color 0.3s ease-out;
    cursor: pointer;
    text-decoration: none;

    svg > * {
      transition: stroke 0.3s ease-out;
    }

    &:hover {
      color: ${props => props.theme.color.brand.primary};

      svg > * {
        stroke: ${props => props.theme.color.brand.primary};
      }
    }
  }
`;

const Category = ({
  onCloseMenu,
  category,
  isMobile,
  navbarFadeClass,
  index
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Root isMobile={isMobile} className={navbarFadeClass} index={index}>
      <CategoryTitleContainer
        onClick={isMobile && handleClick}
        isOpen={isOpen}
        isMobile={isMobile}
      >
        <CategoryName>{category.name}</CategoryName>
        {isMobile && (
          <IconCustom
            name="chevron-right"
            color={isOpen ? color.brand.accent : color.brand.primary}
            size="8px"
            isOpen={isOpen}
          />
        )}
      </CategoryTitleContainer>

      {(isOpen || !isMobile) && (
        <CategoryItems>
          {category.linksDetail.map(item => {
            return (
              <CategoryItem key={item.id} onClick={onCloseMenu}>
                <Link href={item.url} withIcon>
                  {item.label}
                </Link>
              </CategoryItem>
            );
          })}
        </CategoryItems>
      )}
    </Root>
  );
};

Category.propTypes = {
  onCloseMenu: PropTypes.func,
  category: PropTypes.object,
  isMobile: PropTypes.bool,
  navbarFadeClass: PropTypes.string,
  index: PropTypes.number
};

export default translate()(Category);
